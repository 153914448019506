import { useState, useEffect } from 'react';
import { createPortal } from 'react-dom';
import cn from 'classnames';
import { usePopperTooltip } from 'react-popper-tooltip';

export type TooltipTriggerType = 'click' | 'right-click' | 'hover' | 'focus';

export type TooltipPlacement =
  | 'auto'
  | 'auto-start'
  | 'auto-end'
  | 'top'
  | 'top-start'
  | 'top-end'
  | 'bottom'
  | 'bottom-start'
  | 'bottom-end'
  | 'right'
  | 'right-start'
  | 'right-end'
  | 'left'
  | 'left-start'
  | 'left-end';

export type TooltipConfig = Partial<{
  //                             // default value
  closeOnOutsideClick: boolean; // true
  closeOnTriggerHidden: boolean; // false
  defaultVisible: boolean; // false
  delayHide: number; // 0
  delayShow: number; // 0
  followCursor: boolean; // false
  interactive: boolean;
  mutationObserverOptions: MutationObserverInit; // { attributes: true, childList: true, subtree: true }
  offset: [number, number]; // [0, 0]
  onVisibleChange: (state: boolean) => void;
  placement: TooltipPlacement;
  trigger: TooltipTriggerType | null; // 'hover'
  visible: boolean;
}>;

export interface TooltipProps extends TooltipConfig {
  className?: string;
  bodyClass?: string;
  tooltip?: React.ReactNode;
  hideArrow?: boolean;
  width?: number | string;
  nowrap?: boolean;
  inline?: boolean;
  hideOnLiClick?: boolean;
  hideOnAClick?: boolean;
}

const Tooltip: React.FC<TooltipProps> = ({
  className,
  bodyClass,
  children,
  tooltip,
  hideArrow,
  onVisibleChange,
  width,
  nowrap,
  inline,
  interactive = true,
  hideOnLiClick = true,
  hideOnAClick = true,
  offset = [0, 0],
  visible: currentVisible,
  ...tooltipConfig
}) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    if (onVisibleChange) {
      onVisibleChange(isVisible);
    }
  }, [isVisible]);

  useEffect(() => setIsVisible(currentVisible), [currentVisible]);

  const { getTooltipProps, setTooltipRef, setTriggerRef, visible } = usePopperTooltip({
    ...{ ...tooltipConfig, interactive, offset, visible: isVisible, onVisibleChange: setIsVisible },
  });

  const handleClick = (event) => {
    if (
      (hideOnLiClick && event.target.nodeName === 'LI') ||
      (hideOnAClick && event.target.nodeName === 'A')
    ) {
      setIsVisible(false);
    }
  };

  return (
    <div
      className={cn(`pm-tooltip`, {
        [`pm-tooltip--nowrap`]: nowrap,
        [`pm-tooltip--inline`]: inline,
      })}
    >
      <div className="pm-tooltip__trigger" ref={setTriggerRef}>
        {children}
      </div>
      {visible &&
        createPortal(
          <div
            onClick={handleClick}
            ref={setTooltipRef}
            {...getTooltipProps({
              className: cn(`pm-tooltip__wrapper`, className, { [`pm-tooltip--nowrap`]: nowrap }),
              style: { width },
            })}
          >
            <div className={cn(`pm-tooltip__body`, bodyClass)}>{tooltip}</div>
          </div>,
          document.body,
        )}
    </div>
  );
};

export default Tooltip;
